<template>
    <section class="content">
        <header class="content__title">
            <h1>Scripture Union News</h1>
            <small>A list of news spanning various categories</small>
            <div class="actions">
                <button class="btn btn-primary" @click="add">
                    <i class="zmdi zmdi-plus"></i>
                    Add New
                </button>
            </div>
        </header>
        <router-view :key="$route.fullPath" />
        <div class="clearfix"></div>
        
    </section>
</template>


<script>
export default {
    data(){
        return {

            news: [],
        }
    },
    methods: {
        add(){
            this.$router.push("/news/add")
        },

    }
}
</script>